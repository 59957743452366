import { useEffect, useState } from "react";
import useWalletAccount from "../web3/hooks/useWalletAccount";
import { supportedChains } from "../web3/chains";
import { Chain } from "viem";

export const useDestinationChainList = () => {
  const { connectedChain, connectedChainId } = useWalletAccount();
  const [destinationChainList, setDestinationChainList] = useState<
    Chain[] | null
  >(null);

  useEffect(() => {
    if (connectedChain) {
      setDestinationChainList(
        supportedChains.filter((chain) => chain.id !== connectedChainId)
      );
    }
  }, [connectedChain]);

  return { destinationChainList };
};
