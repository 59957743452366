"use client";
import Modal from "../common/Modal";
import { config } from "../../web3/config";
import { Address, erc20Abi } from "viem";
import { userBalanceProp } from "../../types/types";
import useWalletAccount from "../../web3/hooks/useWalletAccount";
import { readContract, waitForTransactionReceipt } from "wagmi/actions";
import { useBridgeContext } from "@/context/bridgeProvider";
import { fromWei } from "../../utils/web3BigNumbers";
import { useEffect, useMemo, useState } from "react";
import SwitchNetworkModalBody from "../common/SwitchNetworkModalBody";
import { writeContract } from "@wagmi/core";

interface Prop {
  token: userBalanceProp;
  refetchOftBalance: () => void;
  setSelectedTokenForSwap: (token: userBalanceProp | null) => void;
}

const SwapModalBody = ({
  token,
  refetchOftBalance,
  setSelectedTokenForSwap,
}: Prop) => {
  const { account, connectedChainId } = useWalletAccount();
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [convertLoading, setConvertLoading] = useState<boolean>(false);
  const [isApproved, setIsApproved] = useState(false);
  const { handleSwapToReal } = useBridgeContext();

  const [checkingApprove, setCheckingApprove] = useState<boolean>(true);

  const getAllowance = async () => {
    setCheckingApprove(true);
    try {
      const res = await readContract(config, {
        abi: erc20Abi,
        address: token.tokenAddress as Address,
        functionName: "allowance",
        args: [account as Address, token.gateWayAddress as Address],
        chainId: token.chainId,
      });
      setIsApproved(res >= token.userTokenBalance!);
    } catch (e) {
      console.log(e);
    } finally {
      setCheckingApprove(false);
    }
  };

  const isRightChain = useMemo(() => {
    return connectedChainId === token.chainId;
  }, [connectedChainId, token.chainId]);

  useEffect(() => {
    getAllowance();
  }, []);

  const handleApproveMbToken = async () => {
    if (!token.userTokenBalance) return;
    setApproveLoading(true);
    try {
      const res = await writeContract(config, {
        abi: erc20Abi,
        address: token.tokenAddress as Address,
        functionName: "approve",
        args: [token.gateWayAddress as Address, token.userTokenBalance],
      });
      await waitForTransactionReceipt(config, { hash: res });
      await getAllowance();
    } catch (e) {
      console.log(e);
    } finally {
      setApproveLoading(false);
    }
  };

  const convertToken = async () => {
    try {
      setConvertLoading(true);
      await handleSwapToReal(token);
      refetchOftBalance();
      setSelectedTokenForSwap(null);
    } catch (e) {
      console.log(e);
    } finally {
      setConvertLoading(false);
    }
  };

  useEffect(() => {
    if (isApproved && isRightChain) convertToken();
  }, [isApproved, isRightChain]);

  if (!isRightChain) {
    return <SwitchNetworkModalBody token={token} />;
  }

  return (
    <div className="flex flex-col gap-5 w-full items-center justify-center min-h-[110px]">
      {!checkingApprove && (
        <div className="flex flex-col gap-5 items-center">
          <div className="-mt-5">
            Convert{" "}
            {fromWei(token.userTokenBalance!.toString(), token.decimals)}{" "}
            {token.tokenName}
          </div>
          <div className="flex gap-5">
            <div className={`flex ${isApproved && "text-green-500"}`}>
              <div>1-</div>
              <div>{isApproved ? "Approved" : "Approve"}</div>
            </div>

            <div className={` flex ${convertLoading && "text-orange-500"}`}>
              <div>2-</div>
              <div>{convertLoading ? "Converting... " : "Convert"}</div>
            </div>
          </div>
          {!isApproved && (
            <div
              className={`flex flex-col gap-3 ${
                approveLoading && "text-orange-500"
              }`}
            >
              <button
                className="radial-gradient-button cursor-pointer "
                onClick={() => handleApproveMbToken()}
              >
                {approveLoading ? "Approving..." : "Approve"}
              </button>
            </div>
          )}
          {isApproved && !convertLoading && (
            <button
              className="radial-gradient-button cursor-pointer"
              onClick={() => convertToken()}
            >
              Convert
            </button>
          )}
          {isApproved && convertLoading && <div> {"> > >"} </div>}
        </div>
      )}
    </div>
  );
};

interface ModalProps {
  refetchOftBalance: () => void;
}

const SwapModal = ({ refetchOftBalance }: ModalProps) => {
  const { selectedTokenForSwap, setSelectedTokenForSwap } = useBridgeContext();
  const handelOpenModal = () => {
    setSelectedTokenForSwap(null);
  };
  return (
    <div>
      <Modal
        titleLeft="Convert"
        isOpen={!!selectedTokenForSwap}
        closeModalHandler={handelOpenModal}
        size="small"
      >
        <SwapModalBody
          token={selectedTokenForSwap!}
          refetchOftBalance={refetchOftBalance}
          setSelectedTokenForSwap={setSelectedTokenForSwap}
        />
      </Modal>
    </div>
  );
};

export default SwapModal;
