"use client";
import useWalletAccount from "../../../web3/hooks/useWalletAccount";
import { useEffect, useState } from "react";
import { ChainsLogo, supportedChains } from "../../../web3/chains";
import { useDestinationChainList } from "../../../hooks/useDestinationChainList";
import Modal from "../../../components/common/Modal";
import NetworksModal from "../../../components/NetworksModal/NetworksModal";
import { useBridgeContext } from "@/context/bridgeProvider";

export const DestinationNetworkToDisplay = () => {
  const { destinationChain, setDestinationChain } = useBridgeContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { connectedChainId, isWrongNetWork, connectedChain } =
    useWalletAccount();
  const { destinationChainList } = useDestinationChainList();

  useEffect(() => {
    if (!isWrongNetWork && connectedChain && !destinationChain) {
      setDestinationChain(
        supportedChains.filter((chain) => chain.id !== connectedChainId)[0]
      );
    }

    if (destinationChain && connectedChainId === destinationChain.id) {
      setDestinationChain(
        supportedChains.filter((chain) => chain.id !== connectedChainId)[0]
      );
    }
  }, [connectedChain]);

  const handelOpenModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div>
      {destinationChain && (
        <div className="cursor-pointer" onClick={() => handelOpenModal()}>
          <img src={ChainsLogo[destinationChain.id]} width={35} />
        </div>
      )}

      <Modal
        titleLeft="Select NetWork"
        isOpen={isModalOpen}
        closeModalHandler={handelOpenModal}
        size="large"
      >
        <NetworksModal
          networks={destinationChainList!}
          closeModalHandler={handelOpenModal}
          isDestination={true}
        />
      </Modal>
    </div>
  );
};
