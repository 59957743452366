"use client";
import { config } from "../config";
import { Address, erc20Abi } from "viem";
import { MBTokenList } from "../../constants/contractAddresses";
import { useReadContracts } from "wagmi";
import useWalletAccount from "./useWalletAccount";
import { useEffect, useState } from "react";
import { userBalanceProp } from "../../types/types";

export const useFetchOFTBalances = () => {
  const [userOftBalances, setBalances] = useState<userBalanceProp[] | null>();
  const tokenContracts = [];
  const { account } = useWalletAccount();
  const [isBalanceFetched, setIsBalanceFetched] = useState<boolean>(false);

  for (let i = 0; i < MBTokenList.length; i++) {
    const token = MBTokenList[i];
    tokenContracts.push({
      abi: erc20Abi,
      address: token.tokenAddress as Address,
      functionName: "balanceOf",
      args: [account],
      chainId: token.chainId,
    });
  }

  const { data: balanceData, refetch: refetchOftBalance } = useReadContracts({
    config,
    contracts: tokenContracts,
  });

  useEffect(() => {
    if (account) {
      refetchOftBalance();
    }
  }, [account, refetchOftBalance]);

  useEffect(() => {
    if (!balanceData) return;
    const updatedBalance = MBTokenList.map((token, index) => ({
      ...token,
      userTokenBalance: balanceData[index].result as bigint | undefined,
    }));

    setIsBalanceFetched(true);
    setBalances(updatedBalance);
  }, [balanceData]);

  return { userOftBalances, refetchOftBalance, isBalanceFetched };
};
