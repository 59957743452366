import { fromWei } from "../../../utils/web3BigNumbers";
import { ChainsLogo } from "../../../web3/chains";
import { useFetchOFTBalances } from "../../../web3/hooks/useFetchOFTBalances";
import useWalletAccount from "../../../web3/hooks/useWalletAccount";
import { useBridgeContext } from "@/context/bridgeProvider";
import SwapModal from "../../../components/SwapModal/SwapModal";
import { useEffect } from "react";

const MbTokenBalances = () => {
  const { userOftBalances, isBalanceFetched, refetchOftBalance } =
    useFetchOFTBalances();
  const { setSelectedTokenForSwap, status, bridgeHash } = useBridgeContext();

  useEffect(() => {
    refetchOftBalance();
  }, [status, bridgeHash]);

  if (
    !userOftBalances ||
    !isBalanceFetched ||
    !userOftBalances.some(
      (token) =>
        parseFloat(
          fromWei(token.userTokenBalance!.toString(), token.decimals)
        ) > 0
    )
  )
    return <></>;

  return (
    <div className="overflow-x-auto text-white">
      <table className="min-w-full text-center">
        <thead className="">
          <tr className="w-full bg-gray-800 text-white">
            <th className="py-2 px-4 text-center">Network</th>
            <th className="py-2 px-4 text-center">Token</th>
            <th className="py-2 px-4 text-center">Balance</th>
            <th className="py-2 px-4 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {userOftBalances &&
            userOftBalances.some(
              (token) =>
                parseFloat(
                  fromWei(token.userTokenBalance!.toString(), token.decimals)
                ) > 0
            ) &&
            userOftBalances.map(
              (token, index) =>
                token.userTokenBalance! > 0 && (
                  <tr key={index} className="border-b">
                    <td className="py-2 px-4 flex justify-center items-center gap-2">
                      <img
                        src={ChainsLogo[token.chainId]}
                        width={30}
                        height={20}
                      />
                      {token.chainName}
                    </td>
                    <td className="py-2 px-4">{token.tokenName}</td>
                    <td className="py-2 px-4">
                      {fromWei(
                        token.userTokenBalance!.toString(),
                        token.decimals
                      )}
                    </td>
                    <td className="py-2 px-4">
                      <button
                        className="radial-gradient-button "
                        onClick={() => setSelectedTokenForSwap(token)}
                      >
                        Convert
                      </button>
                    </td>
                  </tr>
                )
            )}
        </tbody>
      </table>
      <SwapModal refetchOftBalance={refetchOftBalance} />
    </div>
  );
};

const CheckUserAssets = () => {
  const { isConnected } = useWalletAccount();
  if (isConnected) return <MbTokenBalances />;
  return <></>;
};

export default CheckUserAssets;
